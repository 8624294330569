import React from "react";

const What = () =>{
  return(
    <div>
      <section className="what">
        <h1>What you can do with NativeTalk</h1>
        <div className="whatHold">
          <div className="holder top">
            <div className="holds reveal">
              <span className="img img1"></span>
              <h4>
                Virtual phone numbers at your fingertips
              </h4>
              <p>
                Generate virtual phone numbers at your convenience for making calls or creating another WhatsApp account 😄
              </p>
            </div>
            <div className="holds reveal animate2">
              <span className="img img2"></span>
              <h4>
                Temporary numbers
              </h4>
              <p>
                Create phone numbers for specific purposes like weddings or birthdays and delete when done.
              </p>
            </div>
          </div>
          <div className="holder reveal">
            <div className="holds">
              <span className="img img3"></span>
              <h4>
                Customizable inbound call rates
              </h4>
              <p>
                Get paid when people call you at your personalized rates.
              </p>
            </div>
            <div className="holds reveal animate2">
              <span className="img img4"></span>
              <h4>
                Make unlimited local and international calls
              </h4>
              <p>
                Make local and international calls at the cheapest call rates and via your internet.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default What;