import React from "react";

const Impact = () => {
  return(
    <div>
      <section className="impact mt-4 mt-md-5">
        <h1>Our Impact</h1>
        
        <div className="pactText align-items-center my-3 mt-md-5">
          <div className="hold hold1 flex-column flex-md-row">
            <span></span>
            <div className="align-items-center align-items-md-start text-center text-md-start">
              <h2 className="mb-0">Over 10,000,000</h2>
              <p className="my-0 w-75">
                minutes of calls have been made on NativeTalk
              </p>
            </div>
          </div>

          <div className="hold hold2 flex-column flex-md-row mb-3">
            <span></span>
            <div className="align-items-center align-items-md-start">
              <h2 className="mb-0">3000+</h2>
              <p className="my-0">Businesses</p>
            </div>
          </div>
          
          <div className="hold hold3 flex-column flex-md-row">
            <span></span>
            <div className="align-items-center align-items-md-start">
              <h2 className="mb-0">10,000</h2>
              <p className="my-0">Call agents</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Impact;