import React from "react";

const Val = () =>{
  return(
    <div>
      <section className="val">
        <div>
          <h1>
            You value your customers. Let’s help you prove it.
          </h1>

          {/* <span className="ctaHold"><a href="#some" className="cta text-decoration-none">get started</a></span> */}
        </div>
      </section>
    </div>
  )
}

export default Val;