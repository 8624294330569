import React from "react";

const Cust = () =>{
  return(
    <div>
      <section className="cust">
        <div className="custTop">
          <h1>
            Customer Service Solutions for Modern Businesses
          </h1>
          <p>
            Get these features with our cloud PBX solutions
          </p>
        </div>
        <div className="custBot">
          <div className="hold">
            <div className="holder">
              <span className="img"></span>
              <h4>
                Real-Time Modifications
              </h4>
              <p>
                Toggle your system settings at any time, including adding new numbers or users in a single click.
              </p>
            </div>
            <div className="holder">
              <span className="img img2"></span>
              <h4>
                Live Feed
              </h4>
              <p>
                Gain a real-time perspective of team activity, so you can shift resources and optimise productivity.
              </p>
            </div>
            <div className="holder">
              <span className="img img3"></span>
              <h4>
                Call Queuing
              </h4>
              <p>
                Give inbound callers the option to remain in queue until one of your agents is available to talk.
              </p>
            </div>
          </div>
          <div className="hold">
            <div className="holder">
              <span className="img img4"></span>
              <h4>
                Call Whispering
              </h4>
              <p>
                Secretly speak to teammates while they are on a live call so you can provide timely advice.
              </p>
            </div>
            <div className="holder">
              <span className="img img5"></span>
              <h4>
                CRM Integration
              </h4>
              <p>
                Native Talk integrates with your CRM and Helpdesk tools to keep all conversations connected.
              </p>
            </div>
            <div className="holder">
              <span className="img img6"></span>
              <h4>
                Call Monitoring
              </h4>
              <p>
                Accelerate employee training and boost call quality assurance by monitoring live calls.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Cust;