import React from "react";
import Cloud from "./comp/cloud.jsx";
import '../style/style.css';
import Simp from "./comp/simply.jsx";
import Cust from "./comp/customer.jsx";
import Cent from "./comp/center.jsx";
import Faq from "../../gens/faq.js";
import Testimonial from "../../gens/testimonial.js";
import ScrollToTopOnMount from "../../gens/scrollToTop.js";



const Enterprise = () =>{
  return(
    <div className="mt-5 pt-2 enterprise">
      <ScrollToTopOnMount />
      <Cloud/>
      <Simp/>
      <Cust/>
      <div className="testimonial-wrapper">
        <Testimonial />
      </div>
      <div className="faq-wrapper
      ">
        <Faq />
      </div>      
      <Cent/>
    </div>
  )
}

export default Enterprise;