import React from "react";
import phoneHand from '../../images/phone-hand.png';
import cloud from '../../images/cloud.svg';
import trash from '../../images/trash.svg';
import call from '../../images/calls.svg';
import ring from '../../images/ringing.svg';
import { Link } from "react-router-dom";


const You = () =>{
  return(
    <div>
      <section className="you">
        <div className="hand reveal animate2">
          <img src={phoneHand} alt="smartphone held with hand" />
        </div>
        <div className="youText">
          <h4 className="align-self-center align-self-md-start mb-0">for you</h4>

          <h1>
            Stay connected on your terms
          </h1>

          <p className="mt-2 text-center text-md-start">
            Stay in control of your phone calls and connect on your terms with NativeTalk. Make and receive calls from your NativeTalk mobile app, no SIM card needed. 
          </p>

          <div className="mob reveal">
            <img src={phoneHand} alt="smartphone held with hand" />
          </div> 

          <ul className="ps-0 mt-5 mt-md-2">
            <li className="mb-3">
              <span>
                <img src={cloud} alt="" />
              </span>
              <p className="my-0 ms-2">
                Virtual phone numbers
              </p>
            </li>
            <li className="mb-3">
              <span>
                <img src={trash} alt="" />
              </span>
              <p className="my-0 ms-2">
                Disposable phone numbers
              </p>
            </li>
            <li className="mb-3">
              <span>
                <img src={call} alt="" />
              </span>
              <p className="my-0 ms-2">
                Set customized rates for incomng calls
              </p>
            </li>
            <li className="mb-3">
              <span>
                <img src={ring} alt="" />
              </span>
              <p className="my-0 ms-2">
                Generate unique extensions for specific people like your family, colleagues and friends.
              </p>
            </li>
          </ul>

          <span className="ctaHold"><Link to="/NativetalkPersonal" className="cta text-decoration-none">Learn more</Link></span>
        </div>
      </section>
    </div>
  )
}

export default You;