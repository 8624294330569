import React from "react";
import { Link } from "react-router-dom";
import hand from '../../images/phone-hand.png';
import playStore from '../../images/gplay.svg';
import appStore from '../../images/apple.svg';
import { useEffect } from "react";

const Calls = () =>{
  return(
    <div>
      <section className="call">
        <div className="callText">
          <h1>
            Calls are more exciting with NativeTalk
          </h1>
          <p>
            Do you feel overwhelmed by phone calls? What if you could control when and how people can call you? We built NativeTalk to make your calls something to look forward to.
          </p>
          <div className="callHold reveal">
           <a target="_blank" href='https://play.google.com/store/search?q=nativetalk&c=apps'>
              <img src={playStore} alt="" />
            </a>
            <Link to='#'>
              <img src={appStore} alt="" />
            </Link>
          </div>
        </div>
        <div className="callImg">
          <img src={hand} alt="" />
        </div>
      </section>
    </div>
  )
}

export default Calls;