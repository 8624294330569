import React from "react";
import Hero from "./comp/hero";
import You from "./comp/you";
import Business from "./comp/business";
import Ent from "./comp/ent";
import Impact from "./comp/impact";
import Smart from "./comp/smart";
import Val from "./comp/value";
import Faq from "../../gens/faq";
import '../style/style.css';
import ScrollToTopOnMount from "../../gens/scrollToTop";
import { useEffect } from "react";

const Home = () =>{
  return(
    <div className="mt-5 pt-2">
      <ScrollToTopOnMount />  
      <Hero/>
      <You/>
      <Business/>
      <Ent/>
      <Impact/>
      <div style={{backgroundColor: "#FAFAFA"}} className="reveal">
        <Faq />
      </div>
      <Smart/>
      <Val/>
    </div>
  )
}

export default Home;