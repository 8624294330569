import About from './components/about';
import ScrollToTopOnMount from '../../gens/scrollToTop';
import './style/style.css';
import './style/aboutUs.css';
import Commitment from './components/commitment';


export default function AboutUs() {
    return (
        <div className="AboutUs mt-5 pt-2">
        <ScrollToTopOnMount />  
        <About />
        <Commitment />
        </div>
    )
    
}