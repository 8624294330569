import React from "react";
import { Link } from "react-router-dom";
import playStore from '../../images/gplay.svg';
import appStore from '../../images/apple.svg';


const Look = () =>{
  let isAndroid, isIos;
  
  isAndroid = /Android/i.test(navigator.userAgent);
  isIos = /iPhone|iPad|iPod/i.test(navigator.userAgent);


  return(
    <div>
      <section className="look">
        <div className="lookText">
          <h1>
            Look forward to phone calls
          </h1>
          <p>
            Stay in control with NativeTalk. Get a virtual phone, make and receive calls, experience cheaper call rates and more.
          </p>
          <div className="lookHold">
            <a target="_blank" href='https://play.google.com/store/search?q=nativetalk&c=apps'>
              <img src={playStore} alt="" />
            </a>
            <Link to='#'>
              <img src={appStore} alt="" />
            </Link>
          </div>
          <div className="mob">
            {isAndroid && (
              <a target="_blank" href='https://play.google.com/store/search?q=nativetalk&c=apps' id="mob">
                <img src={playStore} alt="" />
              </a>
            )}
            {isIos && (
              <Link to='#' id="mob">
                <img src={appStore} alt="" />
              </Link>
            )}
          </div>
        </div>
        <div className="lookImg"></div>
      </section>
    </div>
  )
}

export default Look;