import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { store, persistor } from './app/store';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Footer from './gens/footer';
import Header from './gens/header';
import Landing from './landing';
import SelectPlan from './selectPlan';
import ReserveNumber from './reserveNumber';
import ConfirmOrder from './confirmOrder';
import { PrivacyPolicy } from './privacyPolicy';
import { PersistGate } from 'redux-persist/integration/react';
import Loading from './gens/loading';
import './styles/index.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
// import { SuccessfulOtpVerificationModal } from './gens/modals/successful';
import { loadProgressBar } from 'axios-progress-bar';
import 'axios-progress-bar/dist/nprogress.css';
import NotFound from './not-found';
import Home from './v2/homepage/homepage';
import Personal from './v2/personal/personal';
import AboutUs from './v2/aboutus/aboutus';
import Enterprise from './v2/enterprise/enterprise';
import { TermsAndConditions } from './termsAndConditions';
import Small from './v2/small business/smallBusi';
import Demo from './v2/demo/demo';
loadProgressBar()

// Control routing of app
class App extends React.Component {
  componentDidMount() {
    window.addEventListener('scroll', this.reveal);      

    var elements = document.getElementsByClassName("accordion-button");

    // Loop through the elements and apply styles
    for (var i = 0; i < elements.length; i++) {
      elements[i].style.backgroundColor = "#FAFAFA"; // Example: Set background color to light blue
    }

    // Get all elements with the class "accordion-body"
    var elements = document.getElementsByClassName("accordion-body");

    // Loop through the elements and apply styles
    for (var i = 0; i < elements.length; i++) {
      elements[i].style.backgroundColor = "#FAFAFA"; // Example: Set background color to light blue
    }
  }
  
  reveal() {
    let reveals = document.querySelectorAll(".reveal");
  
    let windowHeight = window.innerHeight;
    for (var i = 0; i < reveals.length; i++) {
      let elementTop = reveals[i].getBoundingClientRect().top;
      let elementVisible = 100;
  
      if (elementTop < windowHeight - elementVisible)
        reveals[i].classList.add('active');
      else if (!reveals[i].classList.contains('active')) reveals[i].classList.remove('active');
    }
  }

  render() {
    return (
      <Router>  
        <div >
          <Header />  
          <Routes>
            <Route exact path='/' element={<Home />} />
            <Route exact path='/NativetalkPersonal' element={<Personal />} />
            <Route exact path='/NativetalkBusinesses' element={<Landing />} />
            <Route exact path="/NativetalkEnterprise" element={<Enterprise/>}/>
            <Route exact path='/AboutUs' element={<AboutUs />} />
            <Route exact path='/ConfirmOrder' element={<ConfirmOrder />} />
            <Route exact path='/ReserveNumber' element={<ReserveNumber />} />
            <Route exact path='/SelectPlan' element={<SelectPlan />} />
            <Route exact path='/PrivacyPolicy' element={<PrivacyPolicy />} />
            <Route exact path='/TermsAndConditions' element={<TermsAndConditions />} />
            <Route exact path='/SmallBusiness' element={<Small />} />
            <Route exact path='/Demo' element={<Demo />} />



            <Route path="*" element={<NotFound />} />      
          </Routes>
          <Footer />
        </div>
      </Router>
    )
  }
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
      <PersistGate loading={<Loading />} persistor={persistor}>
        <App />
      </PersistGate>  
    </Provider>
);
