import React from "react";
import { Link } from "react-router-dom";
import slice from "../../images/slice.svg";
import five from "../../images/five.svg";
import lord from "../../images/lord.svg";
import play from "../../images/playg.svg";



const Cloud = () =>{
  return(
    <div>
      <section className="cloud align-items-start">
        <div className="cloudText">
          <h1>
            Cloud-based contact center solutions for Enterprises
          </h1>
          <p>
            Power your internal and customer-facing communications in minutes with the NativeTalk VoIP call centre system. Run your call centre in the cloud today. 
          </p>
          {/* <div className="linker">
            <Link to='' className="cta">
              Schedule a demo
            </Link>
            <Link to='' className="noCta">
              or Contact Sales
            </Link>
          </div> */}
          <div className="vid mob">
            {/* <img src={play} alt="" /> */}
            <iframe id="vid" height="300" width="200" src='https://youtube.com/embed/o3gX1N5_Hnc' frameborder="0"></iframe>
          </div>
          {/* <div className="trust">
            <p>Trusted by</p>
            <ul>
              <li><img src={slice} alt="" /></li>
              <li><img src={five} alt="" /></li>
              <li><img src={lord} alt="" /></li>
            </ul>
          </div> */}
        </div>
        <div className="vid m-3">
          {/* <img src={play} alt="" /> */}
          <iframe id="vid" height="442.24" src='https://youtube.com/embed/o3gX1N5_Hnc' frameborder="1"></iframe>
        </div>
      </section>
    </div>
  )
}

export default Cloud;