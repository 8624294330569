import rde1 from '../../images/Mask group.png';

export default function About() {
    return (
        <>
        <section class="hero about">
            <div class="container">
                <div class="hero-content">
                    <h1>About NativeTalk</h1>
                    <p>
                        NativeTalk is a technology company with a laser focus on making virtual phone systems available to all. 
                        <br /><br />
                        For many years, we focused on providing VoIP (Voice over internet protocol) solutions to enterprise companies and gradually expanded to making our services available to businesses and personal users.
                        <br />
                        <br />
                        We currently deliver and maintain a market-leading voice platform with exceptional customer service and the will to stand out through innovation and reliability
                    </p>
                </div>
            </div>
            <div class="hero-image">
                <img src={rde1} alt="Woman making call in the market" />
            </div>
        </section>
        </>
    )
}