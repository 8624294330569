import React from 'react';
import logo from '../styles/images/logo.png';

import { Link } from 'react-router-dom';
import $ from 'jquery';
import { HashLink } from "react-router-hash-link";
import { useState } from 'react';

// import Dropdown from 'react-bootstrap/Dropdown';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';


export default function Header() {
    const [navbarToggle, setNavbarToggle] = useState(false);
    const toggleNavbar = () => {
      if (navbarToggle) $('#headerNavbar').addClass('collapse')
      else $('#headerNavbar').removeClass('collapse')

      setNavbarToggle(!navbarToggle)
    }

    return(
        <>
            <header className ="Header fixed-top">
            <nav class="navbar py-3 navbar-expand-lg bg-white px-2" id="header-nav">
              <div class="container mx-0 mx-md-5 px-4 px-md-0">
                <Link className="navbar-brand" to="/">
                  <img src={logo} width="130"  alt="" />
                </Link>
                <button className="navbar-toggler" onClick={() => toggleNavbar()} type="button" data-bs-target="#headerNavbar" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <span class="navbar-toggler-icon"></span>
                </button>

                <div class="collapse navbar-collapse" id="headerNavbar">
                  <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                    <li className="nav-item me-3 active" id="nav-item1" >
                            <a className="nav-link" href="/" >Home</a>
                    </li>
                    <li className="nav-item me-3" id="nav-item2">
                        <Link className="nav-link" to="/AboutUs" >About us</Link>
                    </li>

                    <li class="nav-item me-3 dropdown" id='nav-item4'>
                        <Navbar.Collapse>
                        <Nav>
                            <NavDropdown
                            title="Product"
                            menuVariant="light"
                            
                            >
                            <NavDropdown.Item>
                              <Link to="NativetalkPersonal" className='text-black text-decoration-none'>Personal</Link>
                            </NavDropdown.Item>
                            <NavDropdown.Item>
                              <Link to="SmallBusiness" className='text-black text-decoration-none'>Business</Link>
                            </NavDropdown.Item>
                            <NavDropdown.Item>
                              <Link to="NativetalkEnterprise" className='text-black text-decoration-none'>Enterprise</Link>
                            </NavDropdown.Item>
                            {/* <NavDropdown.Item>
                              <Link to="SmallBusiness" className='text-black text-decoration-none'>Small Business</Link>
                            </NavDropdown.Item> */}

                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                </li>

                <li class="nav-item me-3 dropdown" id='nav-item4'>
                        <Navbar.Collapse>
                        <Nav>
                            <NavDropdown
                            title="Resources"
                            menuVariant="light"
                            >
                            <NavDropdown.Item>Blog</NavDropdown.Item>
                            <NavDropdown.Item>
                              <Link to="AboutUs" className='text-black text-decoration-none'>About us</Link>
                            </NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                </li>
                    <li className="nav-item me-3 " id="nav-item5">
                        <Link className="nav-link" to="/SelectPlan">Pricing</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
            
        </header>
        </>    
    )
}
