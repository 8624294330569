import React from "react";
// import { Link } from "react-router-dom";
// import callWoman from "../../../styles/images/call-woman.png"

const Cent = () =>{
  return(
    <div>
      <section className="cent mt-5">
        <div className="centText">
          <h1>
            Looking for white-labelled call centre solutions?
          </h1>
          <p>
            Leverage our well-documented APIs and white labelled contact centre solutions to match your brand.
          </p>
          <span className="ctaHold">
            <a href="mailto:support@nativetalk.io" className="cta">
              Contact sales
            </a>
          </span>
        </div>
        <div className="centImg">
          {/* <img src={callWoman}/> */}
        </div>
      </section>
    </div>
  )
}

export default Cent;