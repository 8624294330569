import React from "react";
import { Link } from "react-router-dom";
import finger from "../../images/3fingers.png";

const Start = () =>{
  return(
    <div>
      <section className="start">
        <div className="startText">
          <h1>
            Get Started in 3 steps
          </h1>
          <p>
            Do you feel overwhelmed by phone calls? What if you could control when and how people can call you? We built NativeTalk to make your calls something to look forward to.
          </p>
          <ul>
            <li className="reveal animate2">
              <span></span>
              Download NativeTalk
            </li>
            <li className="reveal">
              <span></span>
              Create a free account
            </li>
            <li>
              <span></span>
              Enjoy your new virtual phone
            </li>
          </ul>
          {/* <span className="ctaHold">
            <Link to='/' className="cta text-decoration-none ">
              Request a demo
            </Link>
          </span> */}
        </div>
        <div className="startImg">
          <img src={finger} alt="" />
        </div>
      </section>
    </div>
  )
}

export default Start;