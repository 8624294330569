import React from "react";

const Benefit = () =>{
    return(
        <div>
            <section className="benefit">
                <div className="top">
                    <p>
                        BENEFITS
                    </p>
                    <h1>
                        Get a phone number that grows your business
                    </h1>
                    <p>
                        Do more with a phone number that helps you talk to customers professionally, upgrade your customer service and drive sales. 
                    </p>
                </div>
                <div className="bot">
                    <div className="holder">
                        <div>
                            <span className="img"></span>
                            <h4>
                                Organise your business communications
                            </h4>
                            <p>
                                Generate a phone number to separate your business and personal calls in 10 minutes without buying a SIM card or new phone.
                            </p>
                        </div>
                        <div>
                            <span className="img"></span>
                            <h4>
                                Build a relationship with customers
                            </h4>
                            <p>
                                Get powerful tools to personalise your calls with customers and build loyalty with an unforgettable customer experience.
                            </p>
                        </div>
                    </div>
                    <div className="holder">
                        <div>
                            <span className="img img3"></span>
                            <h4>
                                Grow your business effortlessly
                            </h4>
                            <p>
                                Access call insights to grow your business and keep overhead costs low with our scalable phone system.
                            </p>
                        </div>
                        <div>
                            <span className="img img2"></span>
                            <h4>
                                Transform your customer experience
                            </h4>
                            <p>
                                Make self-service options available to customers for a fast and easy buying experience.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Benefit;