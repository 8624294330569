import peep from '../../../styles/images/peep.png';

export default function Commitment() {
    return (
        <>
        <section className='commit'>    
            <div class="foot">
                <div class="container-1">
                    <h1>Our Commitment</h1>
                    <p>
                        We believe that customer experience in Africa can be more exceptional with the right business tools. 
                        <br /><br />
                        Our business communication solutions are designed for SMEs, Startups and Enterprises who want to transform their customer experience and make their customers feel valued. With NativeTalk’s virtual phone system, you can grow your business without sacrificing customer experience or taking on capital-intensive overhead cost . 
                        <br /><br />
                        Our personal app is designed for creators and other digital natives who want to maintain their privacy and connect on their terms in an increasingly open world.
                    </p>
                </div>
            </div>
            <div class="container-image">
                <img src={peep} alt="motivation" />
            </div>
            {/* <div class="footer-1">
                <div class="feet">
                    <h1>As Featured In</h1>
                </div>
            </div> */}
        </section>
        </>
    )
}