import React from "react";
import './style/busi.css';
import ScrollToTopOnMount from "../../gens/scrollToTop";
import Testimonial from "../../gens/testimonial";
import Faq from "../../gens/faq";
import Hero from "./comp/hero";
import Benefit from "./comp/benefits";
import Features from "./comp/features";
import Val from "../homepage/comp/value";


const Small = () =>{
    return(
        <div>
            <ScrollToTopOnMount />
            <Hero/>
            <Benefit/>
            <Features/>
            <div className="testimonial-wrapper">
                <Testimonial />
            </div>
            <div className="faq-wrapper">
                <Faq />
            </div>    
            <Val/>
        </div>
    )
}

export default Small;