import React, { useState } from "react";

const Dem = () =>{
    const [formData, setFormData] = useState({
        full_name: '',
        to_email: 'support@nativetalk.io',
        email_template: "template3",
        email: '',
        phone: '',
        compWeb: '',
        compName: '',
        size: '',
        about: '',
    });

    const [loading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState(null);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
    };

    const submitForm = () => {
        setLoading(true);
        fetch('https://crm.nativetalkapp.com:447/flsk/nt-mail', {
        method: 'POST',
        headers: {
            'Authorization': '919c02f004339822ecb967cc526977b528f7ba55',
            'company': `${formData.compName}, ${formData.compWeb}`
        },
        body: JSON.stringify(formData)
        })
        .then(response=>{
        return response.json();
        })
        .then(data => {
        console.log('API call successful:', data);
        setSuccessMessage("Form sent successfully");
        })
        .catch(error => {
        console.error('Error making API call:', error.message);
        })
        .finally(() => {
        setLoading(false);
        });        
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Do something with the form data, e.g., send it to a server
        // console.log('Form submitted:', formData);
        submitForm();
    };

    return(
        <div className="">
            <section className="demo">
                <h1>Get a customised solution for your enterprise</h1>
                <div className="formHold">
                    <form onSubmit={handleSubmit}>
                        <input type="text" placeholder="full name" name="full_name" value={formData.full_name} onChange={handleInputChange} required/>
                        <input type="text" placeholder="Work Email" name="email" value={formData.email} onChange={handleInputChange} required/>
                        <input type="text" placeholder="Phone Number (Optional)" name="phone" value={formData.phone} onChange={handleInputChange} />
                        <input type="text" placeholder="Company Name" name="compName" value={formData.compName} onChange={handleInputChange} required/>
                        <input type="text" placeholder="Company Website" name="compWeb" value={formData.compWeb} onChange={handleInputChange}/>
                        <div>
                            <label htmlFor="company size">Company Size: 
                                <select name="size" id="stuff" onChange={handleInputChange}>
                                    <option value=""></option>
                                    <option value="2 - 50">2 - 50</option>
                                    <option value="50 - 100">50 - 100</option>
                                    <option value="100 and above">100 and above</option>
                                </select>
                            </label>
                        </div>
                        <textarea id="" cols="30" rows="10" placeholder="Tell us more about your company" name="about" value={formData.about} onChange={handleInputChange}></textarea>
                        <button type="submit" className="cta" disabled={loading}>
                          {loading ? "Submitting..." : "Submit"}
                        </button>
                        {/* <button type="submit" className="cta" >Submit</button> */}
                    </form>
                    {successMessage && <p className="success-message">{successMessage}</p>}
                    {loading && (
                        <div className="loader-overlay">
                        <div className="loader-spinner"></div>
                        </div>
                    )}
                    {/* <span className="ctaHold">
                        <button type="submit" className="cta">Submit</button>
                    </span> */}
                </div>
            </section>
        </div>
    )
}

export default Dem;