import React from "react";
import image from "../../images/smartphone\ floating\ 1.png";
import { Link } from "react-router-dom";

const Smart = () =>{
  return(
    <div>
      <section className="smart flex-column flex-md-row">
        <div className="smartText">
          <h1>
            Smart phone number for better conversations
          </h1>
          <div className="smartHold">
            <Link to="/NativetalkPersonal">
              <span className="ctaHold"><a href="#some" className="cta text-decoration-none">for personal use</a></span>
            </Link>
            <Link to="/NativetalkBusinesses">
              <span className="ctaHold"><a href="#some" className="cta text-decoration-none">for business</a></span>
            </Link>
          </div>
        </div>
        <img src={image} />
      </section>
    </div>
  )
}

export default Smart;