import React from "react";

const Simp = () =>{
  return(
    <div>
      <section className="simp">
        <div className="simpTop">
          <h1>
            Simplify your business communications
          </h1>
          <p>
            Create better sales and service experiences with our customer-centric call centre solutions
          </p>
        </div>
        <div className="opt">
          <div className="top" id="opt">
            <div className="hold">
              <span className="img"></span>
              <span className="text">
                <h4>
                  Quick Set up
                </h4>
                <p>
                  Set up your call centre for thousands of users in 24hours.
                </p>
              </span>
            </div>
            <div className="hold">
              <span className="img img2"></span>
              <span className="text">
                <h4>
                  Scale with ease
                </h4>
                <p>
                  Manage higher call volumes than a typical inbound call centre without reducing the quality of your customer experience.
                </p>
              </span>
            </div>
          </div>
          <div className="bot" id="opt">
            <div className="hold">
              <span className="img img3"></span>
              <span className="text">
                <h4>
                  Controlled costs
                </h4>
                <p>
                  Take more customer calls with fewer agents thanks to our automated call solutions.
                </p>
              </span>
            </div>
            <div className="hold">
              <span className="img img4"></span>
              <span className="text">
                <h4>
                  Dedicated account manager
                </h4>
                <p>
                  24/7 support with your own dedicated account manager
                </p>
              </span>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Simp;