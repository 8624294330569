import React from "react";
import { Link } from "react-router-dom";

const Features = () =>{
    return(
        <div>
            <section className="features">
                <div className="top">
                    <p>
                        FEATURES
                    </p>
                    <h1>
                        Same digits, extra features to manage your phone calls
                    </h1>
                </div>
                <div className="bot">
                    <div className="holder">
                        <div className="hold">
                            <span className="img"></span>
                            <h4>
                                Virtual numbers
                            </h4>
                            <p>
                                No need for new SIMs or queuing for SIM registration. Generate a phone number online and make calls from the NativeTalk mobile and desktop app. 
                            </p>
                        </div>
                        <div className="hold hold2">
                            <span className="img"></span>
                            <h4>
                                Call pop
                            </h4>
                            <p>
                                Never forget a customer’s name or information with a screen pop-up that appears on every incoming call. 
                            </p>
                        </div>
                        <div className="hold hold3">
                            <span className="img"></span>
                            <h4>
                                Call analytics
                            </h4>
                            <p>
                                Make better business decisions with call data like Talk Time, Abandoned Calls etc. Measuring agent performance is easy with scheduled reports and visual call analytics available inside our call centre software.
                            </p>
                        </div>
                    </div>
                    <div className="holder">
                        <div className="hold hold4">
                            <span className="img"></span>
                            <h4>
                                Unlimited call extensions
                            </h4>
                            <p>
                                Create call extensions for multiple team members in seconds and they can make calls on their personal phones or laptops with the NativeTalk app.
                            </p>
                        </div>
                        <div className="hold hold5">
                            <span className="img"></span>
                            <h4>
                                Interactive Voice Response
                            </h4>
                            <p>
                                Provide self-service options with NativeTalk IVR to customers and free up your team’s time to focus on other tasks that need their attention.
                            </p>
                        </div>
                        <div className="hold hold6">
                            <span className="img"></span>
                            <h4>
                                Omnichannel
                            </h4>
                            <p>
                                Enhanced customer experience by seamlessly integrating various channels. Your business will be able to provide customers with a consistent and personalised experience and cohesive interaction leading to increased satisfaction.
                            </p>
                        </div>
                    </div>
                    <div className="holder mob">
                        <div className="hold hold7">
                        <span className="img"></span>
                        <h4>
                            Call forwarding
                        </h4>
                        <p>
                            Offline or unavailable? Forward your calls to phone numbers of your choice.
                        </p>
                        </div>
                        <div className="hold hold8">
                        <span className="img"></span>
                        <h4>
                            Call hunting
                        </h4>
                        <p>
                            Never miss a call from customers again. Forward calls to multiple phone numbers until it finds one where the call is answered.
                        </p>
                        </div>
                        <div className="hold hold9">
                        <span className="img"></span>
                        <h4>
                            Call queuing
                        </h4>
                        <p>
                            Minimize customer wait time by automating your calls to ring on multiple devices at the same time so any available member of your team can attend to customers.
                        </p>
                        </div>
                    </div>
                </div>
                <span className="ctaHold">
                    <Link to="/Demo" target="_blank" className="cta">
                        Request a demo
                    </Link>
                </span>
            </section>
        </div>
    )
}

export default Features;