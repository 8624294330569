import React from "react";
import { Link } from "react-router-dom";
import phones from "../../images/phones.png";

const Hero = () =>{
    return(
        <div>
            <section className="smallHero mt-5 mb-5">
                <div className="text m-3">
                    <div className="top mb-5">
                        <h1>
                            Business solutions for Smart Entrepreneurs
                        </h1>
                        <h1 className="mob">
                            Contact centre solutions for Smart Entrepreneurs
                        </h1>
                        <p>
                            Make customer service simple and grow your business with a NativeTalk business phone system.
                        </p>
                        <span className="ctaHold">
                            <Link to="/NativetalkBusinesses" target="_blank" className="cta">
                                Generate a business phone in 30 secs
                            </Link>
                        </span>
                    </div>
                </div>
                <div className="img pt-5 pb-5 ">
                    <img src={phones} alt="" />
                </div>
            </section>
        </div>
    )
}

export default Hero;