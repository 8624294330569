import React from "react";
import Look from "./comp/look";
import '../style/style.css';
import Calls from "./comp/calls";
import What from "./comp/what";
import Start from "./comp/started";
import { useEffect } from "react";
import Faq from "../../gens/faq";
import ScrollToTopOnMount from "../../gens/scrollToTop";


const Personal = () =>{
  return(
    <div className="mb-5 overflow-hidden personal">
      <ScrollToTopOnMount />  
      <Look/>
      <Calls/>
      <What/>
      <div className="mt-5">
        <Start/>
      </div>
      <div style={{backgroundColor: "#EFF5FB", paddingTop: "150px", paddingBottom: "100px"}}>
        <Faq color="faded-blue-bg" />
      </div>
    </div>
  )
}

export default Personal;