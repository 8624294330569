import React from "react";
// import './style/demo.css';
import './style/demo.css';
import Dem from "./comp/dem";

const Demo = () =>{
   return(
    <div>
        <Dem/>
    </div>
   )
}

export default Demo;