import React from 'react';
import ScrollToTopOnMount from './gens/scrollToTop';

export class TermsAndConditions extends React.Component {    
    render() {
        return (
            <div className='my-5 py-5 pl-7vw pr-7vw official-docs'>
                <ScrollToTopOnMount />
                <h1>TERMS AND CONDITIONS</h1>
            
                <h5>Last update: 01 January, 2023</h5>
                <div>
                    1. <b>Introduction</b><br/>
                    Welcome to NativeTalk Communications (“Company”, “we”, “our”, “us”)!
                    These Terms of Service (“Terms”) govern your use of our application – NativeTalk (together 
                    or individually “Service”).
                    Our Privacy Policy also governs your use of our Service and explains how we collect, 
                    safeguard and disclose information that results from your use of our web pages. 
                    Your agreement with us includes these Terms and our Privacy Policy (“Agreements”). 
                    You acknowledge that you have read and understood Agreements, and agree to be bound 
                    by them.
                    If you do not agree with (or cannot comply with) Agreements, then you may not use the 
                    Service, but please let us know by emailing at support@Nativetalk.io so we can try to find a 
                    solution. 
                    These Terms apply to all visitors, users and others who wish to access or use Service.                   
                </div>
                <div>
                    2. <b>Communications</b><br/>
                    By using our Service, you agree to subscribe to newsletters, marketing or promotional 
                    materials and other information we may send. However, you may opt out of receiving any, 
                    or all, of these communications from us by following the unsubscribe link or by emailing at 
                    <a href="mailto:support@nativetalk.io">support@nativetalk.io</a> 
                </div>
                <div>
                    3. <b>Purchases</b><br/>
                    If you wish to purchase any product or service made available through Service (“Purchase”), 
                    you may be asked to supply certain information relevant to your Purchase including but not 
                    limited to, your credit or debit card number, the expiration date of your card, your billing 
                    address, and your shipping information.

                    You represent and warrant that: (i) you have the legal right to use any card(s) or other 
                    payment method(s) in connection with any Purchase; and that (ii) the information you 
                    supply to us is true, correct and complete.
                    We may employ the use of third-party services for the purpose of facilitating payment and 
                    the completion of Purchases. By submitting your information, you grant us the right to 
                    provide the information to these third parties subject to our Privacy Policy.
                    We reserve the right to refuse or cancel your order at any time for reasons including but not 
                    limited to: product or service availability, errors in the description or price of the product or 
                    service, error in your order or other reasons.
                    We reserve the right to refuse or cancel your order if fraud or an unauthorized or illegal 
                    transaction is suspected.
                </div>
                <div>
                    4. <b> Contests, Sweepstakes and Promotions</b><br/>
                    Any contests, sweepstakes or other promotions (collectively, “Promotions”) made available
                    through Service may be governed by rules that are separate from these Terms of Service.
                </div>
                <div>
                    5. <b>Refunds</b><br/>
                    <b>NativeTalk</b> has a no refund policy. However, we have a support team to guide you through 
                    setup and answer any questions you may have.
                </div>
                <div>
                    6. <b> Prohibited Uses</b><br/>
                    You may use Service only for lawful purposes and in accordance with Terms. You agree not
                    to use Service:
                    0.1. In any way that violates any applicable national or international law or regulation.
                    0.2. For the purpose of exploiting, harming, or attempting to exploit or harm minors in any 
                    way by exposing them to inappropriate content or otherwise.
                    0.3. To transmit, or procure the sending of, any advertising or promotional material, 
                    including any “junk mail”, “chain letter,” “spam,” or any other similar solicitation.
                    0.4. To impersonate or attempt to impersonate Company, a Company employee, another 
                    user, or any other person or entity.
                    0.5. In any way that infringes upon the rights of others, or in any way is illegal, threatening, 
                    fraudulent, or harmful, or in connection with any unlawful, illegal, fraudulent, or 
                    harmful purpose or activity.
                    0.6. To engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of 
                    Service, or which, as determined by us, may harm or offend Company or users of 
                    Service or expose them to liability.

                    Additionally, you agree not to:
                    0.1. Use Service in any manner that could disable, overburden, damage, or impair Service or 
                    interfere with any other party’s use of Service, including their ability to engage in real 
                    time activities through Service.
                    0.2. Use any robot, spider, or other automatic device, process, or means to access Service 
                    for any purpose, including monitoring or copying any of the material on Service.
                    0.3. Use any manual process to monitor or copy any of the material on Service or for any 
                    other unauthorized purpose without our prior written consent.
                    0.4. Use any device, software, or routine that interferes with the proper working of Service.
                    0.5. Introduce any viruses, trojan horses, worms, logic bombs, or other material which is 
                    malicious or technologically harmful.
                    0.6. Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of 
                    Service, the server on which Service is stored, or any server, computer, or database 
                    connected to Service.
                    0.7 Take any action that may damage or falsify Company ratings.
                    0.7. Otherwise attempt to interfere with the proper working of Service
                </div>
                <div>
                    7. <b>Analytics</b><br/>
                    We may use third-party Service Providers to monitor and analyze the use of our Service.
                </div>
                <div>
                    8. <b> Intellectual Property</b><br/>
                    Service and its original content (excluding Content provided by users), features and 
                    functionality are and will remain the exclusive property of NativeTalk Communications Ltd 
                    and its licensors. Service is protected by copyright, trademark, and other laws of and foreign 
                    countries. Our trademarks may not be used in connection with any product or service 
                    without the prior written consent of NativeTalk Communications Ltd.
                </div>
                <div>
                    9. <b>Copyright Policy</b><br/>
                    We respect the intellectual property rights of others. It is our policy to respond to any claim 
                    that Content posted on Service infringes on the copyright or other intellectual property 
                    rights (“Infringement”) of any person or entity.
                    If you are a copyright owner, or authorized on behalf of one, and you believe that the 
                    copyrighted work has been copied in a way that constitutes copyright infringement, please 
                    submit your claim via email to support@nativetalk.io, with the subject line: “Copyright 
                    Infringement” and include in your claim a detailed description of the alleged Infringement as 
                    detailed below, under “DMCA Notice and Procedure for Copyright Infringement Claims”
                    You may be held accountable for damages (including costs and attorneys’ fees) for 
                    misrepresentation or bad-faith claims on the infringement of any Content found on and/or 
                    through Service on your copyright.
                </div>
                <div>
                    10. <b>DMCA Notice and Procedure for Copyright Infringement Claims</b><br/>
                    You may submit a notification pursuant to the Digital Millennium Copyright Act (DMCA) by 
                    providing our Copyright Agent with the following information in writing (see 17 U.S.C 
                    512(c)(3) for further detail):
                    0.1. an electronic or physical signature of the person authorized to act on behalf of the 
                    owner of the copyright’s interest;
                    0.2. a description of the copyrighted work that you claim has been infringed, including the 
                    URL (i.e., web page address) of the location where the copyrighted work exists or a copy 
                    of the copyrighted work;
                    0.3. identification of the URL or other specific location on Service where the material that 
                    you claim is infringing is located;
                    0.4. your address, telephone number, and email address;
                    0.5. a statement by you that you have a good faith belief that the disputed use is not 
                    authorized by the copyright owner, its agent, or the law;
                    0.6. a statement by you, made under penalty of perjury, that the above information in your 
                    notice is accurate and that you are the copyright owner or authorized to act on the 
                    copyright owner’s behalf.
                    You can contact our Copyright Agent via email at support@nativetalk.io.
                </div>
                <div>
                    11. <b> Error Reporting and Feedback</b><br/>
                    You may provide us either directly at support@nativetalk.io or via third party sites and tools 
                    with information and feedback concerning errors, suggestions for improvements, ideas, 
                    problems, complaints, and other matters related to our Service (“Feedback”). You 
                    acknowledge and agree that: (i) you shall not retain, acquire or assert any intellectual 
                    property right or other right, title or interest in or to the Feedback; (ii) Company may have 
                    development ideas similar to the Feedback; (iii) Feedback does not contain confidential 
                    information or proprietary information from you or any third party; and (iv) Company is not 
                    under any obligation of confidentiality with respect to the Feedback. In the event the 
                    transfer of the ownership to the Feedback is not possible due to applicable mandatory laws, 
                    you grant Company and its affiliates an exclusive, transferable, irrevocable, free-of-charge, 
                    sub- licensable, unlimited and perpetual right to use (including copy, modify, create 
                    derivative works, publish, distribute and commercialize) Feedback in any manner and for 
                    any purpose.
                </div>
                <div>
                    12. <b> Links To Other Websites</b><br/>
                    Our Service may contain links to third party web sites or services that are not owned or
                    controlled by NativeTalk Communications. 
                    NativeTalk Communications has no control over, and assumes no responsibility for the 
                    content, privacy policies, or practices of any third-party web sites or services. We do not 
                    warrant the offerings of any of these entities/individuals or their websites.

                    YOU ACKNOWLEDGE AND AGREE THAT COMPANY SHALL NOT BE RESPONSIBLE OR LIABLE, 
                    DIRECTLY OR INDIRECTLY, FOR ANY DAMAGE OR LOSS CAUSED OR ALLEGED TO BE CAUSED 
                    BY OR IN CONNECTION WITH USE OF OR RELIANCE ON ANY SUCH CONTENT, GOODS OR 
                    SERVICES AVAILABLE ON OR THROUGH ANY SUCH THIRD-PARTY WEB SITES OR SERVICES.
                    WE STRONGLY ADVISE YOU TO READ THE TERMS OF SERVICE AND PRIVACY POLICIES OF 
                    ANY THIRD-PARTY WEB SITES OR SERVICES THAT YOU VISIT.
                </div>
                <div>
                    13. <b>Disclaimer Of Warranty</b><br/>
                    THESE SERVICES ARE PROVIDED BY COMPANY ON AN “AS IS” AND “AS AVAILABLE” BASIS. 
                    COMPANY MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR 
                    IMPLIED, AS TO THE OPERATION OF THEIR SERVICES, OR THE INFORMATION, CONTENT OR 
                    MATERIALS INCLUDED THEREIN. YOU EXPRESSLY AGREE THAT YOUR USE OF THESE 
                    SERVICES, THEIR CONTENT, AND ANY SERVICES OR ITEMS OBTAINED FROM US IS AT YOUR 
                    SOLE RISK.
                    NEITHER COMPANY NOR ANY PERSON ASSOCIATED WITH COMPANY MAKES ANY 
                    WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, 
                    RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE SERVICES. WITHOUT LIMITING 
                    THE FOREGOING, NEITHER COMPANY NOR ANYONE ASSOCIATED WITH COMPANY 
                    REPRESENTS OR WARRANTS THAT THE SERVICES, THEIR CONTENT, OR ANY SERVICES OR 
                    ITEMS OBTAINED THROUGH THE SERVICES WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR 
                    UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT THE SERVICES OR THE SERVER 
                    THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR 
                    THAT THE SERVICES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL 
                    OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
                    COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR 
                    IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES 
                    OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.
                    THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR 
                    LIMITED UNDER APPLICABLE LAW.
                </div>

                <div>
                    14 <b>Amendments to Terms</b><br/>
                    We may amend Terms at any time by posting the amended terms on this site. It is your
                    responsibility to review these Terms periodically.
                    Your continued use of the Platform following the posting of revised Terms means that you 
                    accept and agree to the changes. You are expected to check this page frequently so you are 
                    aware of any changes, as they are binding on you.
                    By continuing to access or use our Service after any revisions become effective, you agree to 
                    be bound by the revised terms. If you do not agree to the new terms, you are no longer 
                    authorized to use Service.
                </div>
                <div>
                    15. <b>Acknowledgement</b><br/>
                    BY USING OUR SERVICES OR OTHER SERVICES PROVIDED BY US, YOU ACKNOWLEDGE THAT YOU 
                    HAVE READ THESE TERMS OF SERVICE AND AGREE TO BE BOUND BY THEM.
                </div>
                <div>
                    16. <b> Contact Us</b><br/>
                    Please send your feedback, comments, requests for technical support by email:
                    support@nativetalk.io
                </div>
            </div>
        )  
    }
}