import React from "react";
import hands from '../../images/hands-holding-phones.png';
import { Link } from "react-router-dom";


const Ent = () =>{
  return(
    <div>
      <section className="ent">
        <div className="entImg reveal">
          <img src={hands} alt="" />
        </div>
        
        <div className="entText">
          <h4>
            NATIVETALK ENTERPRISE
          </h4>
          <h1>
            Team of 1 or 50+, we’ve got a solution for you
          </h1>
          <p className="text-center text-md-start mt-3">
            Set up a call centre for your large business and company in 24 hours with our Call Center software. Take advantage of our white labelling options and easy-to-use APIs designed to integrate with your existing CRM and streamline your sales and customer service workflows. 
          </p>
          <span className="ctaHold mt-3"><Link to="/NativetalkEnterprise" className="cta text-decoration-none ">learn more</Link></span>
        </div>
      </section>
    </div>
  )
}

export default Ent;