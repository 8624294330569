import React from "react";
// import africa from '../../images/africa.png';
import rde1 from '../../../styles/images/rde1.png';
// import play from '../../images/play.svg';
import caller from '../../images/calling.png'
// import player from '../../images/play-white.png';
import ng from '../../../styles/images/nig.svg';
import gh from '../../../styles/images/ghana.svg';
import ke from '../../../styles/images/kenya.svg';
import sa from '../../../styles/images/SA.svg';
import ci from '../../../styles/images/ivory.svg';
import tz from '../../../styles/images/tanzania.svg';
import playBtn from '../../../styles/images/play_btn.svg';
import { Link } from "react-router-dom";



const Hero = () => {
  return(
    <div>
      <section className="hero">
        <div className="heroText pt-3">
          <h1>
            powering communications <span>across</span> africa
          </h1>

          <h1 className="mob">
            powering communications <span>across</span> africa
          </h1>

          <p className="mb-0">
            NativeTalk provide solutions designed to power communication at every level. Equipping businesses and individuals with the tools and technologies they need to connect, collaborate, and succeed in today's fast-paced digital world.
          </p>
          
          <div className="d-flex justify-content-between w-50 flags">
            <div className="flag-container me-3">
              <img src={ng} alt="Nigeria" className="flag" />
              <span className="flag-name">Nigeria</span>
            </div>
            <div className="flag-container me-3">
              <img src={gh} alt="Ghana" className="flag" />
              <span className="flag-name">Ghana</span>
            </div>
            <div className="flag-container me-3">
              <img src={ke} alt="Kenya" className="flag" />
              <span className="flag-name">Kenya</span>
            </div>
            <div className="flag-container me-3">
              <img src={sa} alt="South Africa" className="flag" />
              <span className="flag-name">South Africa</span>
            </div>
            <div className="flag-container me-3">
              <img src={ci} alt="Ivory Coast" className="flag" />
              <span className="flag-name">Ivory Coast</span>
            </div>
            <div className="flag-container">
              <img src={tz} alt="Tanzania" className="flag" />
              <span className="flag-name">Tanzania</span>
            </div>
          </div>
          <span className="ctaHold mt-4">
            <Link to="https://youtu.be/-xYC0EV3ZDE?si=URns1J8dSTdjcSq2" target="_blank" className="cta vid text-decoration-none">
              <img src={playBtn} alt="Tanzania" className="flag me-3" />
              <span>See why we built Nativetalk</span>
            </Link>
          </span>
        </div>

        <div className="heroDesk">
          <img src={rde1} alt=""  />
          {/* <span>
            <img src={play} alt="" />
            Tap here to see why we built Nativetalk
          </span> */}
        </div>

        <div className="heroMob mob">
          <img src={caller} alt="" />
          {/* <img id="play" src={player} alt="" /> */}
        </div>
      </section>
    </div>
  )
}

export default Hero;