import Carousel from "react-bootstrap/Carousel";
import OpenQuote from '../styles/images/OpenQuote.png';
import CloseQuote from '../styles/images/CloseQuote.png';


export default function Testimonial() {
    return (
        <>
        <div className="d-flex flex-column align-items-center">
        <h1 className="fw-700 testimonial-header">Testimonials</h1>
            <Carousel className="shadow carousel bg-white testimonial position-relative mb-5 py-4 lh-30px" variant="success" >
                        <Carousel.Item className='testimonial-item '>
                            <img src={OpenQuote} alt="" className="open-quote position-absolute"
                            style={{'left': '30px'}}/>
                            <p className="mt-5">Getting a new SIM is often the first step when starting a business. Due to the hassle,
                            some entrepreneurs rely on their personal phone numbers. What if you could get a phone
                            number specially designed for businesses like yours? That’s why we built NativeTalk.</p>
                            <p className="fw-600 mt-4 pt-3 mb-0">XXXXXXXXXX</p>
                            <p className="faded-text">xxxxxxxx LTD</p>
                            <img src={CloseQuote} alt="" className="close-quote position-absolute"
                            style={{'right': '30px', bottom: '0px'}}/>
                        </Carousel.Item>    
                        {/* <Carousel.Item className='testimonial-item'>
                            <img src={OpenQuote} alt="" className="open-quote position-absolute"
                            style={{'left': '30px'}}/>
                            <h1 className="fw-700 mt-4 pt-3 testimonial-header">Testimonials</h1>
                            <p className="mt-5">Getting a new SIM is often the first step when starting a business. Due to the hassle,
                            some entrepreneurs rely on their personal phone numbers. What if you could get a phone
                            number specially designed for businesses like yours? That’s why we built NativeTalk.</p>
                            <p className="fw-600 mt-4 pt-3 mb-0">Lydia Tech4mation</p>
                            <p className="faded-text">Tech4mation LTD</p>
                            <img src={CloseQuote} alt="" className="close-quote position-absolute"
                            style={{'right': '30px', bottom: '0px'}}/>
                        </Carousel.Item>
                        <Carousel.Item className='testimonial-item'>
                            <img src={OpenQuote} alt="" className="open-quote position-absolute"
                            style={{'left': '30px'}}/>
                            <h1 className="fw-700 mt-4 pt-3 testimonial-header">Testimonials</h1>
                            <p className="mt-5">Getting a new SIM is often the first step when starting a business. Due to the hassle,
                            some entrepreneurs rely on their personal phone numbers. What if you could get a phone
                            number specially designed for businesses like yours? That’s why we built NativeTalk.</p>
                            <p className="fw-600 mt-4 pt-3 mb-0">Lydia Tech4mation</p>
                            <p className="faded-text">Tech4mation LTD</p>
                            <img src={CloseQuote} alt="" className="close-quote position-absolute"
                            style={{'right': '30px', bottom: '0px'}}/>
                        </Carousel.Item>     */}
                    </Carousel>
        </div>
            </>
    )
}