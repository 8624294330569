import React from "react";
import phones from '../../images/phones.png';
import { Link } from 'react-router-dom';


const Business = () =>{
  
  return(
    <div>
      <section className="business">
        <div className="busiText align-items-center align-items-md-start">
          <h4>
            NATIVETALK BUSINESS
          </h4>

          <h1>
            One phone number to power and grow your business
          </h1>

          <p className="text-center text-md-start mt-3">
            We help small business owners improve their customer service and grow their business with NativeTalk Business phone system. Separate your business and personal calls. Provide self-service options to customers and drive sales with your NativeTalk phone number.
          </p>

          <span className="ctaHold mt-3"><Link to="/NativetalkBusinesses" className="cta text-decoration-none">Reserve a Number</Link></span>
        </div>
        <div className="busiImg reveal mt-5 mt-md-0">
          <img src={phones} alt="" />
        </div>
      </section>
    </div>
  )
}

export default Business;